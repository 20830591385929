import React from 'react';

import { Link, Section } from '../../components';
import { Layout, SEO } from '../../layout';
import { Offscreen } from '../../components/styled';

export default function Stories() {
  const customers = [
    {
      name: 'Avios',
      alt: 'Find out how Avios optimised its card-linked loyalty programme by creating more opportunities for customers to earn points',
    },
    {
      name: 'TopCashback',
      alt: 'See how TopCashback increased its customer base by utilising Fidel API’s integrations with Visa, Mastercard and Amex',
    },
    {
      name: 'Perkbox',
      alt: 'Why Perkbox chose Fidel API to reduce friction for employees at the point of purchase, and provide businesses with a clear ROI',
    },
    {
      name: 'Stampfeet',
      alt: 'How Stampfeet and Fidel API increased customer engagement by 20% with card-linking',
    },
    {
      name: 'Lux',
      alt: 'How LUX Rewards created a luxury rewards brand and increased average transaction value by 3x',
    },
    {
      name: 'Miconex',
      alt: 'How Mi Rewards uses Fidel API to build citywide loyalty programmes and support local businesses',
    },
  ];

  return (
    <Layout>
      <SEO title="Customer Stories" />
      <Section className="narrow centered">
        <h1>Customer stories</h1>
        <p>
          Discover the ways Fidel API is helping innovative teams create next
          generation applications and accomplish more together.
        </p>
      </Section>
      <Section className="wide two-columns">
        {customers.map(customer => {
          const thumb = getAsset(`main_thumb_${customer.name.toLowerCase()}`);
          const logo = getAsset(
            `main_thumb_${customer.name.toLowerCase()}_logo`,
          );

          return (
            <Link
              key={customer.name}
              to={`/stories/${customer.name.toLowerCase()}`}
              className="story-card story-card-large"
            >
              <Offscreen>{customer.name}</Offscreen>
              <img
                className="story-card-img"
                src={thumb.src}
                srcSet={thumb.srcSet}
                alt={customer.alt}
              />
              <div className="logo-wrapper">
                <img
                  className={`${customer.name.toLowerCase()}-logo`}
                  src={logo.src}
                  srcSet={logo.srcSet}
                  alt={`Read ${customer.name}’s story`}
                />
              </div>
            </Link>
          );
        })}
      </Section>
    </Layout>
  );
}

function getAsset(name) {
  const src = require(`../../modules/stories/images/${name}.png`);
  const src2x = require(`../../modules/stories/images/${name}@2x.png`);
  const srcSet = `${src.default}, ${src2x.default} 2x`;

  return { src, srcSet };
}
